import Accordions from './classes/accordions';
import Observer from '../scripts/includes/observer.min'

const OBSERVER = Observer.getInstance();

// Permet d'initialiser les rubrics avec un offset dynamique ou sans de offset
// Pour un offset dynamique, on doit ajouter des fonctions (ex: onResize, onCloseAlert) pour changer le offset
export default function rubrics() {
  const rubric = new Accordions({
    containerClass: 'tiroirs',
    accordionClass: 'tiroir',
    toggleButtonClass: 'js-tiroir-toggle',
    contentClass: 'js-tiroir-content',
    openedClass: 'open',
  });

  const onResize = () => {
    const headerDesktop = document.querySelector('.js-header-desktop');
    const headerMobile = document.querySelector('.js-header-mobile');
    let offsetHeader = 0;

    if (window.matchMedia("(max-width: 1024px)").matches) {
      offsetHeader = headerMobile.clientHeight;
    } else {
      offsetHeader = headerDesktop.clientHeight;
    }

    rubric.offset = -offsetHeader;
  }

  onResize();
  rubric.init();

  OBSERVER.add({
    name: 'rubrics',
    events: 'resize',
    targets: 'window',
    function: onResize,
  });
  OBSERVER.on('rubrics');
}
