import $ from 'jquery';

//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

// Si la taille de la fenêtre est modifié, fermeture du sidepanel s'il est ouvert
export function closeAllOnResize () {
	var width = $(window).width();

	$(window).on( 'resize', function() {
		let newWidth = $(window).width()
		if (newWidth == width) {
			return
		} else {
			width = $(window).width()
			$('html').removeClass('showDropDownHeader')
			$('html').removeClass('showMenuMobile')
			$('html').removeClass('showOverlayRecherche')
			$('html').removeClass('showOverlayPartager')
			if($('html').hasClass('noscroll')) removeNoScroll()
		}
	})
}


// Fonction pouvant fermer le menu mobile
export function closeMenuMobile() {
	$('.btnSubLvl1 > a').on('click', function() {
		// Fermeture de tout avant d'en ouvrir un autre
		if(!$(this).parent().hasClass('active')) {
			$('.btnSubLvl1').removeClass('active');
			$('.subLvl1').animate({height: 'hide'}, {duration: 400, specialEasing: { height: 'easeInOutCubic' }} )
			$('.subLvl1').removeClass('open');
		}
		// Gestion ouverture et fermeture
		if(!$(this).parent().find('.subLvl1').hasClass('open')) {
			$(this).parent().find('.subLvl1').addClass('open')
			$(this).parent().find('.subLvl1').animate({height: 'show'}, {duration: 400, specialEasing: { height: 'easeInOutCubic' }} )
			$(this).parent().addClass('active')
		} else {
			$(this).parent().find('.subLvl1').removeClass('open')
			$(this).parent().find('.subLvl1').animate({height: 'hide'}, {duration: 400, specialEasing: { height: 'easeInOutCubic' }} )
			$(this).parent().removeClass('active')
		}
	})
}


// Fonction gérant le drop down du header
export function dropDownHeader() {
	var activeDropDown = ''
    var delay = 0
    var setTimeoutConst

	$('.primaryNav ul li:not(:last-child) a').hover( function() {
        var self = $(this)
        setTimeoutConst = setTimeout(function(){
            delay = 300
    		$('html').addClass('showDropDownHeader')
    		$('html').removeClass( activeDropDown )
    		activeDropDown = self.data('section')
    		$('html').addClass( activeDropDown )
        }, delay)
	}, function() {
        clearTimeout(setTimeoutConst)
    })

	$('#overlay, .btnRecherche, .secondaryNav, .primaryNav > a').on('mouseover', function() {
        delay = 0
		$('html').removeClass( activeDropDown )
		$('html').removeClass('showDropDownHeader')
	})

	$('#dropDownHeader a').on('click', function() {
        delay = 0
		$('html').removeClass( activeDropDown )
		$('html').removeClass('showDropDownHeader')
	})
}


// Fonction permettant d'ajouter une classe au html
export function addClassHTML(element, classe, close, noScroll, backToTop) {
	// Paramètres optionnels
	addNoScroll = (typeof addNoScroll === 'undefined') ? false : addNoScroll
	backToTop   = (typeof backToTop   === 'undefined') ? false : backToTop

	$(element).on('click', function(){
		if(!$('html').hasClass(classe)) {
			$('html').addClass(classe)
			if(noScroll == true) addNoScroll(backToTop)
		} else {
			$('html').removeClass(classe)
			if(noScroll == true) removeNoScroll()
		}
	})

	$(close).on('click', function(){
		$('html').removeClass(classe)
		if(!$(element).hasClass('stillNoScoll')) {
			if(noScroll == true) removeNoScroll()
		}
	})
}


// Fonction empêchant le scroll sur le body lorsqu'elle est appelé
export function addNoScroll(backToTop) {
	if(backToTop) $(document).scrollTop(0)
	if ($(document).height() > $(window).height()) {
		let scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop()
		$('html').addClass('noscroll').css('top',-scrollTop)
	}
}


// Fonction rétablissant le scroll sur le body lorsqu'elle est appelé
export function removeNoScroll(scrollTop) {
	// Paramètres optionnels
	scrollTop = (typeof scrollTop === 'undefined') ? false : scrollTop
	if(scrollTop) {
		$('html').removeClass('noscroll')
		$('html,body').scrollTop(0)
	} else {
		var scrollPosition = parseInt($('html').css('top'))
		$('html').removeClass('noscroll')
		$('html,body').scrollTop(-scrollPosition)
	}
}


// si on ferme l’alerte, on envoi un retour au système pour mettre à jour le cookie.
export function closeAlerteFinish() {
    $.request('modaleslist::onCloseModale', {
        data: { 'id': $('#overlayAlerte .close').data('id') },
        complete: function () {
            $(self).addClass('active')
            $('.toggleBlock').toggleClass('active')
        },
        flash: 1,
        loading: $.oc.stripeLoadIndicator,
        handleFlashMessage: function (message, type) {
            $.oc.flashMsg({text: message, class: type})
        }
    })
}
