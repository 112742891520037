import $ from 'jquery';
import slick from 'slick-carousel/slick/slick.min.js'

  //------------\\
 //--- Slicks ---\\
//----------------\\

// Fonction initialisant le slider de l'accueil
export function slickImage () {
    $('.slickImage .slick').slick({
		arrows: false,
		dots: true,
		appendDots: '.slickImage .nav',
		easing: 'easeInOutExpo',
		fade: true,
		infinite: true,
		autoplay: true,
		draggable: true,
		centerMode: true,
		pauseOnHover: false,
		autoplaySpeed: 6000,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1
	})
}

export function slickActualites() {
    $('.slickActualites .slick').slick({
		arrows: false,
		dots: true,
		appendDots: '.slickActualites .nav',
		easing: 'easeInOutExpo',
		fade: true,
		infinite: true,
		autoplay: false,
		draggable: true,
		centerMode: true,
		pauseOnHover: false,
		autoplaySpeed: 6000,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true
	})
}

export function slickCarrieres() {
  $('.careers-slick .slick').slick({
		arrows: false,
		dots: true,
		appendDots: '.careers-slick__nav',
		customPaging : function(slider, i) {
			const selector = slider.$slides[i]
			const url = selector.getAttribute('data-swiper-custom-icon-url')
			const svgClass = selector.getAttribute('data-swiper-custom-icon-class')
			const text = selector.getAttribute('data-swiper-custom-text')
			return `<button class="careers-slick__button"><svg class="careers-slick__button-svg ${svgClass}" aria-hidden="true" role="img"><use xlink:href="${url}"></use></svg>${text}</button>`;
		},
		fade: true,
		infinite: true,
		autoplay: false,
		pauseOnHover: false,
		autoplaySpeed: 6000,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
	});
}
