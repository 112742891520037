import { initArchEventFilter, initScrollFire } from "./fonctions";
import {
  init, initAccueil, leaveAccueil, initGalerie, initFormulaires, initSearch, initShare, initEmplois, initEmploi, initConseil, initEvenements, initEvenement, initActualites, initContact, initContenu, initCarrieres, initFormulaireEvenementOrganisme,
} from "./global";
import { formsPackage } from "./form";
import { dynamicPackage } from "./dynamic";
import { masksPackage } from "./masks";
import Barba from './includes/barba.min.js';

export function initPageTransition() {
  Barba.Pjax.cacheEnabled = false
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper'
  Barba.Pjax.Dom.containerClass = 'transition-container'
  Barba.Pjax.ignoreClassLink = 'no-transition'
  Barba.Dispatcher.on('initStateChange', function (currentStatus) {
    if ( typeof  hj === 'function' )  hj('vpv', location.pathname); // hotjar
    setTransitionLogo(currentStatus.url)
  })
  Barba.Dispatcher.on('linkClicked', function (e) {
    prepareForTransition()
  })
  Barba.Dispatcher.on('transitionCompleted', function (currentStatus, oldStatus, container) {
    initScrollFire()
  })
  Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck;

  Barba.Pjax.preventCheck = function(evt, element) {
    if (!Barba.Pjax.originalPreventCheck(evt, element)) {
      return false;
    }

    // No need to check for element.href -
    // originalPreventCheck does this for us! (and more!)
    if (/.pdf/.test(element.href.toLowerCase())) {
      return false;
    }

    return true;
  };

  setTransition()
  setView()

  var hasBarba = document.querySelector('#transition-wrapper') !== null;
  if(hasBarba) {
    Barba.Pjax.start()
  }
}


function commonJS() {
  initSearch();
  initShare();
  formsPackage();
  masksPackage();
  dynamicPackage();
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------
// GLOBAL VIEW JS.
// ----------------------------------------------------------------------------------------------------------------------------------------------------
function prepareForTransition() {}

function setTransitionLogo(url) {}

// ----------------------------------------------------------------------------------------------------------------------------------------------------
// SPECIFIC VIEW JS.
// ----------------------------------------------------------------------------------------------------------------------------------------------------
function setView() {

  // ------------------------------ Page Error --------------------------------
  var ErrorView = Barba.BaseView.extend({
    namespace: 'errorView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  ErrorView.init()

  // ------------------------------ Accueil --------------------------------
  var AccueilView = Barba.BaseView.extend({
    namespace: 'accueilView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initAccueil()
      commonJS()
    },
    onLeave: function() {
      leaveAccueil()
    },
    onLeaveCompleted: function() {}
  })
  AccueilView.init()

  // ------------------------------ Galerie --------------------------------
  var GalerieView = Barba.BaseView.extend({
    namespace: 'galerieView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initGalerie()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  GalerieView.init()

  // ------------------------------ Formulaires --------------------------------
  var FormulairesView = Barba.BaseView.extend({
    namespace: 'formulaireView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initFormulaires()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  FormulairesView.init()

  // ------------------------------ Emplois --------------------------------
  var EmploisView = Barba.BaseView.extend({
    namespace: 'emploisView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initEmplois()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  EmploisView.init()

  // ------------------------------ Conseil --------------------------------
  var ConseilView = Barba.BaseView.extend({
    namespace: 'conseilView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initConseil()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  ConseilView.init()

  // ------------------------------ Événements --------------------------------
  var EvenementsView = Barba.BaseView.extend({
    namespace: 'evenementsView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initEvenements()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  EvenementsView.init()

  // ------------------------------ Événements Archives --------------------------------
  var EvenementsArchivesView = Barba.BaseView.extend({
    namespace: 'evenementsArchivesView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initArchEventFilter()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  EvenementsArchivesView.init()

  // ------------------------------ Actualités --------------------------------
  var ActualitesView = Barba.BaseView.extend({
    namespace: 'actualitesView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initActualites()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  ActualitesView.init()

  // ------------------------------ Contact --------------------------------
  var ContactView = Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initContact()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  ContactView.init()

  // ------------------------------ Page de contenu --------------------------------
  var ContenuView = Barba.BaseView.extend({
    namespace: 'contenuView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initContenu()
      commonJS()

      initFormBuilder();
      // if (document.querySelector('#form-example')) {
      //   formsPackage();
      // }
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  ContenuView.init()

  // ------------------------------ Détails d'un emploi --------------------------------
  var EmploiView = Barba.BaseView.extend({
    namespace: 'emploiView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initEmploi()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  EmploiView.init()

  // ------------------------------ Détails d'un événement --------------------------------
  var EvenementView = Barba.BaseView.extend({
    namespace: 'evenementView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initEvenement()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  EvenementView.init()

  // ------------------------------ Détails d'une actualité --------------------------------
  var ActualiteView = Barba.BaseView.extend({
    namespace: 'actualiteView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  ActualiteView.init()

  // ------------------------------ Recherche --------------------------------
  var RechercheView = Barba.BaseView.extend({
    namespace: 'rechercheView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initSearch()
      commonJS()
      // A user was found to match all plain text credentials however hashed credential "password" did not match.
    },
    onLeave: function() {
    },
    onLeaveCompleted: function() {}
  })
  RechercheView.init()

  // ------------------------------ Carrières --------------------------------
  var CarrieresView = Barba.BaseView.extend({
    namespace: 'carrieresView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initCarrieres()
      commonJS()
      // A user was found to match all plain text credentials however hashed credential "password" did not match.
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  CarrieresView.init()

  // ------------------------------ Formulaire événement organisme --------------------------------
  var FormulaireEvenementOrganismeView = Barba.BaseView.extend({
    namespace: 'formulaireEvenementOrganismeView',
    onEnter: function() {},
    onEnterCompleted: function() {
      init()
      initFormulaireEvenementOrganisme()
      commonJS()
    },
    onLeave: function() {},
    onLeaveCompleted: function() {}
  })
  FormulaireEvenementOrganismeView.init()
}


//---------------------------------------------------------------------------------------------------------------------------------------------
// LOADER
// ----------------------------------------------------------------------------------------------------------------------------------------------------

export function Loader() {
  this.id = Math.random() * 1000
  this.reset()
}

Loader.prototype.setContainer = function (domElmnt) {
  this.targetContainer = domElmnt
}

Loader.prototype.start = function () {
  let transit = this
  this.showBar()
  this.theInterval = setInterval(function () {
    transit.intervalChecker()
  }, 1)
  this.setLoadList()
  this.loadingCheck()
}

Loader.prototype.reset = function () {
  this.theInterval = null
  this.targetContainer = false
  this.cbComplete = null
  this.imgLoaded = 0
  this.imgToLoad = 0
  this.loadList = null
  this.setLoadingBar()
}

Loader.prototype.setCompleteCallback = function (callbackComplete) {
  this.cbComplete = callbackComplete
}

Loader.prototype.setLoadList = function () {
  let transit = this
  if (this.targetContainer) {
    var searchlist = this.setContainer + 'div, ' + this.setContainer + ' img'
  } else {
    var searchlist = 'div, img'
  }

  this.loadList = null
  this.loadList = $(searchlist).filter(function () {

    if ($(this).css("background-image").indexOf("url") > -1) {
      return true
    }
    var attr = $(this).attr('src')
    if (typeof attr !== typeof undefined && attr !== false) {
      return true
    }
  })

  this.imgToLoad = 0
  this.loadList.each(function (i, img) {
    transit.imgToLoad++
  })
}

Loader.prototype.loadingCheck = function () {
  let transit = this
  var tabUlr = new Array()

  this.loadList.each(function (i, img) {

    if (this.tagName.toLowerCase() != 'img') {

      var regular = new RegExp(/url\(([^()]*)\)/)
      var bi = $(this).css("background-image").match(regular).pop()
      var regReplace = new RegExp('[\'"]', 'gi')
      bi = bi.replace(regReplace, '') // bug IE, Safari, EDGE, Chrome
      if (tabUlr.indexOf(bi) < 0) {
        tabUlr.push(bi)
      }

    } else {

      var imgsrc = $(this).attr('src')
      if (tabUlr.indexOf(imgsrc) < 0) {
        tabUlr.push(imgsrc)
      }

    }
  });

  this.imgToLoad = tabUlr.length

  for (let n = 0; n < tabUlr.length; n++) {
    $('<img/>').on('load', function () {
      $(this).remove()
      transit.imgLoaded++
    }).on('error', function () {
      $(this).remove()
      transit.imgLoaded++
    }).attr('src', tabUlr[n])
  }
}

Loader.prototype.intervalChecker = function () {
  this.setLoadingBar()
  this.checkForCallback()
}

Loader.prototype.checkForCallback = function () {
  if (this.imgLoaded >= this.imgToLoad) {
    clearTimeout(this.theInterval)
    this.hideBar()
    this.theInterval = null
    for (var i = 0; i < this.cbComplete.length; i++) {
      this.cbComplete[i]()
    }

    // Création de l'événement loadComplete
    $(window).trigger('loadComplete')
  }
}

Loader.prototype.setLoadingBar = function () {
  if (this.imgToLoad > 0) {
    var percent = this.imgLoaded / this.imgToLoad * 100
  } else {
    var percent = 0
  }
  let transit = this
  setTimeout(function () {
    $('#loaderbar').css('width', Math.ceil(percent) + '%')
  })
}

Loader.prototype.hideBar = function () {
  $('#loaderbar').fadeOut()
}

Loader.prototype.showBar = function () {
  $('#loaderbar').fadeIn(100)
}

Loader.prototype.autoDestroy = function () {
  for (let key in this) {
    this[key] = null
  }
}

Loader.prototype.sleep = function (milliseconds) {
  var start = new Date().getTime()
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds) {
      break
    }
  }
}

// ----------------------------------------------------------------------------------------------------------------------------------------------------
// TRANSITION
// ----------------------------------------------------------------------------------------------------------------------------------------------------
function setTransition() {

  var preloader = new Loader()

  var FadeTransition = Barba.BaseTransition.extend({
    start: function() {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this))
    },

    fadeOut: function() {
      let transit = this
      preloader.reset()
      $('.hamburger').removeClass('is-active')
      return $('html').addClass('transition').delay(1500).promise()
    },

    fadeIn: function() {
      var _this = this
      preloader.start()

      if(!window.location.hash){
        window.scrollTo(0, 0)
      }
      preloader.setCompleteCallback( [function() {
        setTimeout(function() {
          $('html').removeClass('transition')
        }, 300)
        _this.done()
      }])

    }
  })

  Barba.Pjax.getTransition = function() { return FadeTransition }
}
