import scrollfire from '../scripts/includes/jquery.scrollfire.min'

// Englobe les iframe de vidéos youtube pour gérer le ratio
export function wrapYtVideo() {
  let i;
  let youtubeVideosOuterHtml;
  let newYoutubeVideosOuterHtml;
  const youtubeVideos = document.querySelectorAll('.dynamic iframe[src*="youtube.com"],.dynamic iframe[src*="vimeo.com"]');
  const youtubeVideosLength = youtubeVideos.length;

  for (i = 0; i < youtubeVideosLength; i += 1) {
    youtubeVideosOuterHtml = youtubeVideos[i].outerHTML;
    newYoutubeVideosOuterHtml = `<div class='videoWrapper'><div class='ytVideo'>${youtubeVideosOuterHtml}</div></div>`;
    youtubeVideos[i].outerHTML = newYoutubeVideosOuterHtml;
  }
}

// Ajouter des marges pour les <p> qui contiennent des boutons
export function adjustButtonsMargins() {
  let i;
  const buttons = document.querySelectorAll('.dynamic p > a.primary-button, .dynamic p > a.secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i += 1) { buttons[i].parentNode.classList.add('buttons'); }

  const buttonsParagraph = document.querySelectorAll('.dynamic p.buttons');
  const buttonsParagraphLength = buttonsParagraph.length;

  for (i = 0; i < buttonsParagraphLength; i += 1) {
    if (buttonsParagraph[i].previousElementSibling !== null) {
      if (!buttonsParagraph[i].previousElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--first');
      }
    }
    if (buttonsParagraph[i].nextElementSibling !== null) {
      if (!buttonsParagraph[i].nextElementSibling.classList.contains('buttons')) {
        buttonsParagraph[i].classList.add('buttons--last');
      }
    }
  }
}

// Ajouter un <span> dans les boutons pour l'animation
export function addSpanToButtons() {
  let i;
  let buttonsInnerHtml;
  let newButtonsInnerHtml;
  const buttons = document.querySelectorAll('.dynamic .primary-button, .dynamic .secondary-button');
  const buttonsLength = buttons.length;

  for (i = 0; i < buttonsLength; i += 1) {
    buttonsInnerHtml = buttons[i].innerHTML;
    newButtonsInnerHtml = `<span>${buttonsInnerHtml}</span>`;
    buttons[i].innerHTML = newButtonsInnerHtml;
  }
}

// Ajouter un <span> dans les liens pour le souligné
export function addSpanToLinks() {
  let i;
  let linksInnerHtml;
  let newLinksInnerHtml;
  const links = document.querySelectorAll('.dynamic a:not(.primary-button):not(.secondary-button)');
  const linksLength = links.length;

  for (i = 0; i < linksLength; i += 1) {
    linksInnerHtml = links[i].innerHTML;
    newLinksInnerHtml = `<span>${linksInnerHtml}</span>`;
    links[i].innerHTML = newLinksInnerHtml;
  }
}

// Ajouter les icones svg pour les boutons et les liens textes
export function addSvgToLinks() {
  const themePath = window.config.theme_path;
  const sltr = '.dynamic a[target=_blank]:not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"])';
  const linksExternal = document.querySelectorAll(sltr);

  linksExternal.forEach((link) => {
    if (!link.querySelector('img')) {
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `/themes/${themePath}/assets/images/icones/symbols.svg#ico-external`,
      );
      svgElem.appendChild(useElem);
      link.appendChild(svgElem);
    }
  });

  const sltr2 = '.dynamic a[href$=".pdf"], .dynamic a[href$=".doc"], .dynamic a[href$=".zip"], .dynamic a.docs';
  const linksDocuments = document.querySelectorAll(sltr2);
  linksDocuments.forEach((link) => {
    if (!link.querySelector('img')) {
      const svgElem = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      const useElem = document.createElementNS('http://www.w3.org/2000/svg', 'use');
      useElem.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'xlink:href',
        `/themes/${themePath}/assets/images/icones/symbols.svg#ico-download`,
      );
      svgElem.appendChild(useElem);
      link.appendChild(svgElem);
      link.setAttribute('target', '_blank');
    }
  });
}

// Ajouter les icones svg pour les boutons et les liens textes
export function manageImageLinks() {
  const links = document.querySelectorAll('.dynamic a');
  links.forEach((link) => {
    if (link.querySelector('img')) {
      link.classList.add('link-image');
    }
  });
}

// Ajouter un target _blank sur tous les liens externes et PDF
// Empêcher une transition si la destination est la page actuelle
export function forceBlankOnExterneAndPdfLinks() {
  const links = document.querySelectorAll('.dynamic a[href]');
  for (let i = 0; i < links.length; i += 1) {
    const linkIsNotBlank = links[i].target !== '_blank';
    const linkIsNotJs = links[i].href !== 'javascript:;';
    const linkIsNotSamePath = window.location.hostname !== links[i].hostname;
    const isExtension = links[i].pathname.split('/').pop().indexOf('.') > -1;
    const linkIsNotSameProtocol = window.location.protocol !== links[i].protocol;

    if ((linkIsNotBlank && linkIsNotJs) && (linkIsNotSamePath || isExtension || linkIsNotSameProtocol)) {
      links[i].setAttribute('target', '_blank');
    }
  }
}

// Le package à appliquer pour les zones dynamiques
export function dynamicPackage() {
  wrapYtVideo();
  adjustButtonsMargins();
  forceBlankOnExterneAndPdfLinks();
  addSpanToButtons();
  addSpanToLinks();
  addSvgToLinks();
  manageImageLinks();
}
