import $ from 'jquery';
import 'eonasdan-bootstrap-datetimepicker';
import { Datepicker } from 'vanillajs-datepicker';
import Observer from '../scripts/includes/observer.min'
import scrollfire from '../scripts/includes/jquery.scrollfire.min'
import chosen from '../scripts/includes/chosen.jquery'

const OBSERVER = Observer.getInstance();

//-------------------------\\
//--- Fonctions générales ---\\
//-----------------------------\\

// Empêche une transition si la destination est la page actuelle
export function noSamePageDestination() {
  var links = document.querySelectorAll('a[href]');
  var cbk = function(e) {
    if(e.currentTarget.href === window.location.href) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  for(var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk)
  }
}


// Initialisation du scrollfire
export function initScrollFire() {
  if( !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    $('.scrollFire').not('.no-anim').addClass('scroll').addClass('fire')
    $('.scrollFire').scrollfire({
      offset: 200,
      topOffset: 0,
      bottomOffset: 0,

      onBottomIn: function( elm, distance_scrolled ) {
        $(elm).removeClass('fire')
      }
    })
  }
}


// Fonction permettant d'avoir un input select custom
export function selectDropDown () {
  $('.linksDrop span').on('click', function() {
    $(this).parent().parent().toggleClass('show-links')
  })
}


// Transforme les selects
export function magicSelect() {
  $(".chosen-select").chosen({ disable_search: true })
}


// Initialisation du fancybox de la galerie
export function initFancyGallery (){
  $(".fancyGallery").fancybox({
    animationEffect: "zoom",
    loop : true
  })
}


// Fonction initiant le fancybox pour la vidéo
export function initFancyVideo (){
  $(".fancyvideo").fancybox({
    padding: 0,
    width:1536,
    height:864,
    autoSize : false,
    openEffect: 'fade',
    closeEffect: 'fade',
    beforeLoad: function() {
      $('html').addClass('videofancy')
    },
    afterClose : function() {
      $('html').removeClass('videofancy')
    }
  })
}

// Mettre le focus sur le champ de recherche quand on clique sur la loupe
export function focusOnField() {
  $('.btnRecherche').on('click',function(){
    setTimeout(function(){
      $('#searchInput').focus()
    }, 100)
  })
}


// Fonction qui gère le label des input
export function fixInput() {
  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input').focusin(function(){ $(this).removeClass('pasVide') })

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('input').focusout(function(){
    ($(this).val() != '' ? $(this).addClass('pasVide') : $(this).removeClass('pasVide'))
  });
}



// Fonction gérant quand le side menu doit avoir un background ou pas
export function sideMenu() {
  $(document).on('scroll', function(){
    if ($(window).scrollTop() > 1){
      $('#sideMenu').addClass('bgWhite')
    } else {
      $('#sideMenu').removeClass('bgWhite')
    }
  })
}


// Gestion de la recherche
export function gestionRecherche() {
  $( "#searchForm" ).submit(function() {
    if ($('#searchInput').val() == '') {
      if ($('#searchInput').attr('placeholder') == 'Que recherchez-vous?')
        $('#searchInput').attr('placeholder', 'Entrez votre mot-clé ici...')
      else
        $('#searchInput').attr('placeholder', 'Que recherchez-vous?')

      $('#searchInput').focus()
    } else {
      Barba.Pjax.goTo($('#searchForm').attr('action') + '?q=' + $('#searchInput').val())
      $('html').removeClass('showDropDownHeader')
      $('html').removeClass('showOverlayRecherche')
    }
    return false
  })

  $('#searchSubmiBtn').click( function() {
    if ($('#searchInput').val() == '') {
      if ($('#searchInput').attr('placeholder') == 'Que recherchez-vous?')
        $('#searchInput').attr('placeholder', 'Entrez votre mot-clé ici...')
      else
        $('#searchInput').attr('placeholder', 'Que recherchez-vous?')

      $('#searchInput').focus()
    } else {
      Barba.Pjax.goTo($('#searchForm').attr('action') + '?q=' + $('#searchInput').val())
      $('html').removeClass('showDropDownHeader')
      $('html').removeClass('showOverlayRecherche')
    }

    return false
  })
}


// Gestion des filtres des emplois
export function initEmploiFilter() {
  $('#newsSelectFilter').chosen();
  $('#newsSelectFilter').change(function(){
    var selectedID = $(this).val()

    $.request('carrierelist::onChangeCategorie',{
      data: { filterId: selectedID },
      update: { 'carrieres/liste': '#carrieresWrapper'},
    })
  })
}


// Gestion des filtres des nouvelles
export function initEventFilter() {
  $('#newsSelectFilter').chosen();
  $('#newsSelectFilter').change(function(){
    var selectedID = $(this).val()

    $.request('evenementlist::onChangeCategorie', {
      data: { filterId: selectedID },
      update: { 'evenements/liste': '#evenementsWrapper'},
      complete: function (data) { data.then(function(data){ calendar() })},
    })
  })
}


// Gestion des filtres des nouvelles
export function initArchEventFilter() {
  $('#newsSelectFilter').chosen();
  $('#newsSelectFilter').change(function(){
    var selectedID = $(this).val()

    $.request('archevenementlist::onChangeCategorie', {
      data: { filterId: selectedID },
      update: { 'evenements/liste': '#evenementsWrapper'},
    })
  })
}

// Gestion des filtres des nouvelles
export function initNewsFilter() {
  $('#newsSelectFilter').chosen()
  $('#newsSelectFilter').change(function(){
    window.location = $(this).val()
  })
}



// FONCTIONS RÉUTILISABLES __________
// Fonction permettant de détruire un carousel Slick
export function destroySlick (carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function(){
    $(carousel).slick('unslick')
  }, delai)
}


// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock (depart, destination, delai, ajustement, mobileAjustement){
  // Paramètres optionnels
  delai      = (typeof delai      === 'undefined') ? 600 : delai
  ajustement = (typeof ajustement === 'undefined') ? 0   : ajustement
  mobileAjustement = (typeof mobileAjustement === 'undefined') ? 0   : mobileAjustement

  if(window.innerWidth <= 1024 || window.innerHeight <= 550) {
    if(typeof mobileAjustement === 'undefined') {
      // Défilement au clique
      $(depart).on( 'click', function(){
        $('html, body').animate({ scrollTop: $(destination).offset().top - ajustement}, delai, 'easeInOutExpo')
      });
    } else {
      // Défilement au clique
      $(depart).on( 'click', function(){
        $('html, body').animate({ scrollTop: $(destination).offset().top - mobileAjustement}, delai, 'easeInOutExpo')
      });
    }
  } else {
    // Défilement au clique
    $(depart).on( 'click', function(){
      $('html, body').animate({ scrollTop: $(destination).offset().top - ajustement}, delai, 'easeInOutExpo')
    });
  }
}


// Fonction permettant de stopper l'animation qui flotte en hover d'un element quelconque
export function floating (element) {
  $(element).on( 'hover', function() {
    $(element + ' .floating').toggleClass('floatingStop');
  });
}

export function toggleMemberBio() {
  $('.bioLink').on( 'click', function() {
    $(this).removeClass('activeLink')
    let ouvert = $(this).siblings('.bio').first().hasClass('open')
    console.log($(this).siblings('.bio').first())
    $(this).siblings('.bio.open').first().animate( {height: 'hide'}, {duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $(this).siblings('.bio.open').first().removeClass('open')
    if (!ouvert) {
      $(this).addClass('activeLink')
      $(this).siblings('.bio').first().addClass('open');
      $(this).siblings('.bio').first().stop(true, false).animate( {height: 'show'}, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}

// Permet de créer un calendrier
export function calendar() {
  if (!document.querySelector('.js-calendar')) {
    // s'il n'y a pas d'events
    return;
  }

  const eventsDatesList = document.querySelector('.js-calendar').dataset.list;
  const eventsDatesEnabled = eventsDatesList.split(',');
  const datepickerCalendar = document.querySelector('.js-calendar');

  Datepicker.locales.fr = {
    days: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
    daysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    daysMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    monthsShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Jui', 'Août', 'Sep', 'Oct', 'Nov', 'Déc'],
    today: "Aujourd'hui",
    monthsTitle: 'Mois',
    clear: 'Effacer',
    weekStart: 0,
    format: 'dd/mm/yyyy',
  };

  // eslint-disable-next-line no-unused-vars
  const datepicker = new Datepicker(datepickerCalendar, {
    language: 'fr',
    prevArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/images/icones/symbols.svg#ico-pointer"></use></svg>`,
    nextArrow: `<svg><use xlink:href="/themes/${window.config.theme_path}/assets/images/icones/symbols.svg#ico-pointer"></use></svg>`,
    maxView: 0,
    format: 'yyyy-mm-dd',
    todayHighlight: true,
    beforeShowDay: (date) => {
      // Rendre seulement les dates de la liste d'événements disponibles
      const allDates = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
      return eventsDatesEnabled.indexOf(allDates) !== -1;
    },
  });

  const onChangeDate = (e) => {
    const theTimestamp = Date.parse(e.detail.date) / 1000; // Le timestamp du datepicker est en millisecondes, il faut le mettre en secondes
    $.request('onChangeDate', {
      data: { dateFilter: theTimestamp },
      update: {'evenements/liste': '#evenementsWrapper', 'evenements/filter':'#filtersWrapper'},
      complete: function (data) { data.then(function(data){ calendar();initEventFilter() })},
    });

  };

  OBSERVER.add({
    name: 'calendar',
    events: 'changeDate',
    targets: '.js-calendar',
    function: onChangeDate,
  });
  OBSERVER.on('calendar');
}

// Permet d'aller au backend
export function backendKeyBind() {
  $('body').on('keydown', (e) => {
    const keycode = e.keyCode ? e.keyCode : e.which;
    if ((e.ctrlKey && keycode === 13) || (e.metaKey && keycode === 13)) {
      window.location = '/administration';
      return false;
    }
    return true;
  });
}

export function setFilter(element) {
  $('.searchFilters').removeClass('active')
  $(element).parent().addClass('active')
}
