import Scroller from '@blankonumerique/blanko-scripts/scroller';
import {
  calendar,
  destroySlick,
  fixInput,
  focusOnField,
  gestionRecherche, initEmploiFilter, initEventFilter, initFancyGallery, initNewsFilter, magicSelect,
  noSamePageDestination,
  scrollToBlock, setFilter, toggleMemberBio
} from "./fonctions";
import { addClassHTML, closeAlerteFinish, closeAllOnResize, closeMenuMobile, dropDownHeader } from "./overlay";
import { slickActualites, slickCarrieres, slickImage } from "./slicks";
import { changeLabelUpload } from "./form";
import { formContact, formEventOrganization, formInfolettre, formJob } from "./validation";
import rubrics from "./rubrics";
import initSopfeuWidget from "./sopfeu";

window.calendar = calendar
window.initEventFilter = initEventFilter
window.setFilter = setFilter
window.openOverlayByscuit = openOverlayByscuit

export function openOverlayByscuit() {
  cc.showSettings(0);
}

/*
  Vérifie si un élément existe à chaque x ms pendant x ms
  - Selector: Classe ou id de l'élément
  - Callback: Fonction à exécuter si l'élément est trouvé
  - CheckFrequencyInMs: Fréquence des checks à chaque x millisecondes
  - timeoutInMs: Temps en millisecondes après lequel la fonction arrêtera de chercher l'élément même s'il n'est pas trouvé
*/
function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
  var startTimeInMs = Date.now();
  (function loopSearch() {
    if (document.querySelector(selector)) {
      callback(); // Callback qui exécute le code dans la variable
      return;
    } else {
      setTimeout(function () {
        if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
          return;
        }
        loopSearch(); // Loop récursif
      }, checkFrequencyInMs);
    }
  })();
}

// Clear les cookies des catégories désactivés (Domaine interne seulement)
function updateCookies(reload = true) {
  let cookies = document.cookie.split('; ');

  if (cookies.some(str => str.includes('cc_cookie_byscuit'))) {
    const cookieCategories = cc.getAllBlocks(); // Toutes les catégories
    const acceptedCats = cc.getUserPreferences().accepted_categories; // Catégories activés

    cookieCategories.forEach(category => {
      // Si la catégorie à des cookies et qu'elle est activé par le user
      if (category.cookie_table && acceptedCats.includes(category.toggle.value)) {
        // Retire le cookie de la liste de cookies à effacer
        category.cookie_table.forEach(cookie => {
          cookies = cookies.filter(name => !name.includes(cookie.COL1));
        })
      }
    });
  }

  // Loop sur chaque cookies du sites qui ne sont pas dans des catégories de Byscuit activés
  cookies.forEach(cookie => {
    let url = window.location.hostname.split('.'); // Split le domaine dans un array à chaque .

    // Loop pour essayer tous les domaines que les cookies peuvent utiliser. Ex: dev.villemontlaurier.qc.ca -> villemontlaurier.qc.ca -> qc.ca -> ca
    while (url.length > 0) {
      // On redéfinit la date d'expiration du cookie au 1 Janvier 1970 pour qu'il se fasse supprimer
      let expiredCookie = `${encodeURIComponent(cookie.split(';')[0].split('=')[0])}=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=${url.join('.')} ;path=/`;
      document.cookie = expiredCookie;

      let path = location.pathname.split('/'); // On split le path actuel dans un array à chaque /

      // Loop pour essayer tous les path que les cookies peuvent utiliser
      while (path.length > 0) {
        document.cookie = expiredCookie + path.join('/'); // Rajoute le path au cookie pour être sûr de supprimer tous les cookies
        path.pop(); // Pop le path pour essayer la combinaison suivante
      };

      url.shift(); // Shift le domaine pour essayer la combinaison suivante
    }
  });

  if (reload) {
    // Reload la page pour que Byscuit supprime les cookies externes de catégories désactivés
    location.reload();
  }
}

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

// FONCTION D'INITIALISATION DES PAGES __________

// Initialisation sur toutes les pages
export async function init() {
  await Scroller.init();
  fixInput()
  dropDownHeader()
  closeAllOnResize()
  noSamePageDestination()
  closeMenuMobile()
  gestionRecherche()
  focusOnField()
  rubrics();
  addClassHTML('.hamburger', 'showMenuMobile', '#overlay, .closeAll', true)
  addClassHTML('.btnRecherche', 'showOverlayRecherche', '#overlay, .closeAll')
  addClassHTML('', 'showOverlayAlerte', '.close', false, false, closeAlerteFinish)

  // Quand Byscuit est init on ajoute les eventlistener pour updater les cookies
  waitForElementToDisplay('#s-rall-bn',function(){
    updateCookies(false);

    // Boutons accepter, refuser et sauvegarder dans la sidebar
    document.querySelector('#s-all-bn').addEventListener('click', updateCookies);
    document.querySelector('#s-rall-bn').addEventListener('click', updateCookies);
    document.querySelector('#s-sv-bn').addEventListener('click', updateCookies)

    // Si le cookie cc_cookie_byscuit existe le bouton #c-t-bn et #c-p-bn sont pas init
    const btnDeclinePopup = document.querySelector('#c-t-bn');
    if (btnDeclinePopup) {
      // Boutons accepter et refuser dans le popup
      document.querySelector('#c-p-bn').addEventListener('click', updateCookies);
      btnDeclinePopup.addEventListener('click', updateCookies);
    }

    if (!getCookie('cc_cookie_byscuit') || getCookie('cc_cookie_byscuit').includes('functional')) {
      let script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');
      script.setAttribute('src', '//platform-api.sharethis.com/js/sharethis.js#property=5bbe672c63bfe9001194c212&product=sop');
      document.body.appendChild(script);
    }

    if (!getCookie('cc_cookie_byscuit') || !getCookie('cc_cookie_byscuit').includes('functional')) {
      if (document.querySelector('.fr-video')) {
        document.querySelectorAll('.fr-video').forEach((vid) => {
          vid.innerHTML = '<div class="placeholder_auto_div" id="ps-fr-video" byscuit-temp-placeholder=""><div id="pd-fr-video" class="placeholder-txt">Veuillez <a class="linkStyle" onclick="cc.showSettings(0);">accepter les fichiers témoins</a> de catégorie Fonctionnel pour afficher cette fonctionnalité.</div></div>';
        })
      }

      if (document.querySelector('.sharethis')) {
        document.querySelectorAll('.sharethis').forEach((vid) => {
          vid.innerHTML = '<div class="placeholder_auto_div" id="ps-sharethis" byscuit-temp-placeholder=""><div id="pd-sharethis" class="placeholder-txt">Veuillez <a class="linkStyle" onclick="cc.showSettings(0);">accepter les fichiers témoins</a> de catégorie Fonctionnel pour afficher cette fonctionnalité.</div></div>';
        })
      }
    }
  }, 1000, 10000);
}

// Initialisation de la page d'accueil
export function initAccueil() {
  slickImage()
  slickActualites()
  $('.scrollDown svg').addClass('floating')
  scrollToBlock('.scrollDown', '.blockNouvelles')
  formInfolettre()
  initSopfeuWidget()
  addClassHTML('.btnPartager', 'showOverlayPartager', '#overlay, #overlayPartager .close', true)
}

export function leaveAccueil() {
  destroySlick('.slickImage .slick')
  destroySlick('.slickActualites .slick')
}


// Initialisation de la page galerie
export function initGalerie() {
  magicSelect()
  initFancyGallery()
}


// Initialisation de la page galerie
export function initFormulaires() {
  magicSelect()
  formInfolettre()
}


// Initialisation de la page formulaire de recherche
export function initSearch() {
  magicSelect()
}

// init le script des bouttons de share
export function initShare() {
  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config);
  }
}


// Initialisation de la page d'emplois
export function initEmplois() {
  magicSelect()
  formJob()
  initEmploiFilter()
  formInfolettre()
  changeLabelUpload()
}


// Initialisation de la page de détails d'un emploi
export function initEmploi() {
  formJob()
  changeLabelUpload()
}


// Initialisation de la page du conseil
export function initConseil() {
  formInfolettre()
  toggleMemberBio()
}


// Initialisation de la page du conseil
export function initEvenements() {
  initEventFilter()
  calendar()
  formInfolettre()
  addClassHTML('.btnPartager', 'showOverlayPartager', '#overlay, #overlayPartager .close', true)
}


// Initialisation de la page d'un événement
export function initEvenement() {
  addClassHTML('.btnPartager', 'showOverlayPartager', '#overlay, #overlayPartager .close', true)
}


// Initialisation de la page des actualités
export function initActualites() {
  magicSelect()
  formInfolettre()
  initNewsFilter()
}


// Initialisation de la page de contact
export function initContact() {
  formContact()
}


// Initialisation de la page du contenu
export function initContenu() {
  formInfolettre()
}


// Initialisation de la page de carrières
export function initCarrieres() {
  formJob()
  changeLabelUpload()
  scrollToBlock('[data-js="scrollToForm"]', '[data-js="form"]', 600, 118, 78)
  scrollToBlock('[data-js="scrollToServices"]', '[data-js="services"]', 600, 118, 78)
  scrollToBlock('[data-js="scrollToMore"]', '[data-js="more"]', 600, 118)
  slickCarrieres()
}


// Initialisation de la page formulaire événement d'un organisme
export function initFormulaireEvenementOrganisme() {
  formEventOrganization()
}
