import $ from 'jquery';
import 'jquery-mask-plugin';

// Le formatage du numéro de téléphone
export function formatPhone() {
  $('.js-mask-phone input').mask('000 000-0000');
}

// Le formatage du code postal
export function formatPostalCode() {
  $('.js-mask-postal-code input').mask('Z0Z 0Z0', {
    translation: {
      Z: {
        pattern: /[A-Z, a-z]/,
      },
    },
  });
  // Forcer les majuscules
  $('.js-mask-postal-code input').keyup(function keyup() {
    $(this).val($(this).val().toUpperCase());
  });
}

// Le package à appliquer pour les masques
export function masksPackage() {
  formatPhone();
  formatPostalCode();
}
