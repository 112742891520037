import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';

//------------------------------\\
//--- Validation de formulaire ---\\
//----------------------------------\\

// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  let messages;

  if ($('html')[0].lang === 'en') {
    messages = {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf.',
      file: 'Please provide a adequate file.',
    };
  } else {
    messages = {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
    };
  }

  return messages;
}


// LES RÈGLES __________
// Règle d'email
export function validationEmail() {
  jQuery.validator.addMethod("courriel", function(value, element) {
    return this.optional(element) || /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(value);
  })
}

// Règle du poids maximal d'un fichier
export function validationFilesize() {
  $.validator.addMethod('filesize', function(value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 1MB')
}


// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
// Fonction formValidation1 : validation de formulaire avant l'update de la feuille de style (22 février 2023)
export function formValidation1(form, submit, rules, messages) {
  $.validator.setDefaults({ignore:[]})
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement : 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr("type") == "radio") {
        error.insertBefore(element.closest('.radioWrapper').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    }
  });
  $(submit).click( function() { $(form).submit() })
}

// Fonction formValidation2 : validation de formulaire après l'update de la feuille de style (22 février 2023)
export function formValidation2(formElement, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: [],
  });
  $(formElement).validate({
    debug: false,
    errorElement: 'div',
    focusInvalid: false,
    invalidHandler: (_, validator) => {
      if (!validator.numberOfInvalids()) {
        return; // eslint-disable-next-line semi
      }

      const nbProjectError = validator.errorList.find((item) => item.element.name === 'nbProject');

      if (nbProjectError === undefined) {
        const headerDesktop = document.querySelector('.js-header-desktop');
        const headerMobile = document.querySelector('.js-header-mobile');
        let offsetHeader = 0;

        if (window.matchMedia("(max-width: 1024px)").matches) {
          offsetHeader = headerMobile.clientHeight;
        } else {
          offsetHeader = headerDesktop.clientHeight;
        }

        $('html, body').animate({ scrollTop: $(validator.errorList[0].element).offset().top - offsetHeader - 50 }, 500);
      }
    },
    rules,
    messages,
    errorPlacement: (error, element) => {
      error.appendTo(element.closest('.js-field').find('.js-form-error'));
    },
    success: (_, element) => {
      // Enlever la classe d'erreur au champ
      const errorField = $(element).closest('.js-field');
      const className = errorField.attr('class').split(' ')[0];
      errorField.removeClass(`${className}--error`);

      // Mettre à jour les attributs et classes
      $(element).removeClass('error').addClass('valid');
      $(element).attr('aria-invalid', 'false');
    },
    highlight: (element) => {
      // Ajouter la classe d'erreur au champ
      const errorField = $(element).closest('.js-field');
      const className = errorField.attr('class').split(' ')[0];
      errorField.addClass(`${className}--error`);

      // Mettre à jour les attributs et classes
      $(element).removeClass('valid').addClass('error');
      $(element).attr('aria-invalid', 'true');
    },
    submitHandler: () => {
      if (typeof handler !== 'undefined') {
        handler();
        return false;
      }
      return true;
    },
  });
  // Mettre à jour la validation d'un select au change
  $(formElement + ' select').on('change', function selectChange() {
    $(this).valid();
  });
  $(submit).on('click', (e) => {
    $(formElement).submit();
  });
}

// Validation du formulaire d'infolettre
export function formInfolettre() {
  validationEmail()

  const m = getMessages()

  const rules = {
    courriel: { required: true, courriel: true },
    confirm: { required: true }
  }
  const messages = {
    courriel: { required: m.required, courriel: m.email },
    confirm: { required: m.required }
  }

  formValidation1('#formInfolettre1', '#submitInfolettre1', rules, messages)
}

// Validation du formulaire de livraison
export function formContact(form, submit) {
  validationEmail()

  const m = getMessages();

  const rules = {
    prenom: { required: true },
    nom: { required: true },
    courriel: { required: true, courriel: true },
    telephone: { required: true, minlength: 12 },
    message: { required: true }
  }
  const messages = {
    prenom: { required: m.required },
    nom: { required: m.required },
    telephone: { required: m.required, minlength: m.phone },
    courriel: { required: m.required, courriel: m.email } ,
    message: { required: m.required },
  }

  formValidation1('#formContact', '#submitContact', rules, messages);
}

// Validation du formulaire d'emploi
export function formJob() {
  validationEmail()
  validationFilesize()

  const m = getMessages();
  const validTypeList = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  const validTypes = validTypeList.join(',')

  const rules = {
    prenom: { required: true },
    nom: { required: true },
    courriel: { required: true, courriel: true },
    cv: { required: true, accept: validTypes, filesize: 1048576 },
    lettre: { required: true, accept: validTypes, filesize: 1048576 }
  }
  const messages = {
    prenom: { required: m.required },
    nom: { required: m.required },
    courriel: { required: m.required, courriel: m.email },
    cv: { required: m.file, accept: m.accept },
    lettre: { required: m.file, accept: m.accept }
  }

  formValidation1('#formJob', '#submitJob', rules, messages);
}

// Validation du formulaire événement d'un organisme
export function formEventOrganization() {
  validationEmail()

  var m = getMessages();

  const rules = {
    'titre': { required: true },
    'category': { required: true },
    'start_date': { required: true },
    'end_date': { required: true },
    'start_time': { required: true },
    'end_time': { required: true },
    'rain[]': { required: true },
    'location': { required: true },
    'address': { required: true },
    'free[]': { required: true },
    'registration[]': { required: true },
    'description': { required: true },
    'organizer_name': { required: true },
    'organization_name': { required: true },
    'organization_phone': { required: true, minlength: 12 },
    'organization_email': { required: true, courriel: true },
    'policy_compliant_request[]': { required: true },
  }
  const messages = {
    'titre': { required: m.required },
    'category': { required: m.required },
    'start_date': { required: m.required },
    'end_date': { required: m.required },
    'start_time': { required: m.required },
    'end_time': { required: m.required },
    'rain[]': { required: m.required },
    'location': { required: m.required },
    'address': { required: m.required },
    'free[]': { required: m.required },
    'registration[]': { required: m.required },
    'description': { required: m.required },
    'organizer_name': { required: m.required },
    'organization_name': { required: m.required },
    'organization_phone': { required: m.required, minlength: m.phone },
    'organization_email': { required: m.required, courriel: m.email },
    'policy_compliant_request[]': { required: m.required },
  }

  formValidation2('#form-event-organization', '#form-event-organization-submit', rules, messages);
}
